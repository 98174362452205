import clsx from 'clsx';
import React, { ButtonHTMLAttributes, MouseEvent as RMouseEvent, useState } from 'react';

import { Color, Direction } from '#shared/UI/Foundation/typing';
import Icon, { IconName, IconSize } from '#shared/UI/Icon';
import Typography, { Variant as TypographyVariant } from '#shared/UI/Typography';
import ConditionalDisplay from '#shared/components/ConditionalDisplay';

import getButtonClassnames from './classnames';
import { ButtonSize, Variant } from './typing';

type ButtonStyleProps = {
  className?: string;
  classNameIcon?: string;
  variant?: Variant;
  size?: ButtonSize;
  color?: Color;
  icon?: IconName;
  direction?: Direction;
};

type ButtonProps = Partial<ButtonStyleProps> & {
  id: string;
  loading?: boolean;
  iconSize?: IconSize;
};

const Button = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>>
>(
  (
    {
      id,
      className = '',
      classNameIcon = '',
      size = 'large',
      variant = 'solid',
      color,
      direction = 'left',
      icon,
      loading,
      disabled,
      children,
      type = 'button',
      onClick,
      iconSize,
      ...htmlProps
    },
    ref,
  ) => {
    const [clickLoading, setClickLoading] = useState<boolean>(false);
    const handleClick = (e: RMouseEvent<HTMLButtonElement, MouseEvent>): void => {
      setClickLoading(true);
      if ('function' === typeof onClick) {
        const promise = onClick(e);
        if ('object' === typeof promise && 'function' === typeof (promise as Promise<any>).then) {
          (promise as Promise<any>).then(() => setClickLoading(false)).catch(() => setClickLoading(false));
        } else {
          setClickLoading(false);
        }
      }
    };

    return (
      <button
        ref={ref}
        className={getButtonClassnames({
          size,
          variant,
          className,
          color,
          direction,
          loading,
          disabled,
        })}
        disabled={disabled}
        type={type}
        id={id}
        {...htmlProps}
        onClick={onClick ? handleClick : undefined}
      >
        {(!!icon || loading || clickLoading) && (
          <Icon
            size={iconSize ?? (size === 'small' ? 'small' : 'medium')}
            icon={loading || clickLoading ? 'loading' : (icon as IconName)}
            className={clsx(classNameIcon, 'leading-unset', (loading || clickLoading) && 'inline-block animate-spin')}
          />
        )}
        <ConditionalDisplay condition={children !== undefined}>
          <Typography variant={`${variant === 'link' ? 'link' : 'button'}-${size}` as TypographyVariant}>
            {children}
          </Typography>
        </ConditionalDisplay>
      </button>
    );
  },
);

export default Button;
