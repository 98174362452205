import React from 'react';
import { Trans } from 'react-i18next';

import Typography from '#shared/UI/Typography';
import sharedPaths from '#shared/constants/paths';
import { getEnvVar } from '#shared/envs';
import { useQuery } from '#shared/hooks/useQuery';

import Google from '#auth/features/Authentication/pages/login/components/LoginWith/Google';
import Qonto from '#auth/features/Authentication/pages/login/components/LoginWith/Qonto';

export const redirectUri = getEnvVar('VITE_AUTH_HOST') + sharedPaths.OAUTH_CALLBACK;

export default (): React.ReactElement => {
  const { r: redirect } = useQuery<{
    r?: string;
  }>();

  return (
    <>
      <div className="relative my-4 w-full border-b border-grey-32">
        <Typography
          variant="text"
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 bg-white p-1 text-grey-60"
        >
          <Trans i18nKey="shared:or" />
        </Typography>
      </div>
      <div className="flex flex-col items-center gap-2">
        <Qonto redirect={redirect} />
        <Google redirect={redirect} />
      </div>
    </>
  );
};
