import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Container from '#shared/UI/Container';
import Loader from '#shared/UI/Loader';
import LogoBrand from '#shared/UI/Logo';
import Typography from '#shared/UI/Typography';
import { getEnvVar } from '#shared/envs';
import { useProfile } from '#shared/hooks/useProfile';
import { useQuery } from '#shared/hooks/useQuery';
import { PersonaType } from '#shared/typings/enums/persona';
import { logoutPath } from '#shared/utils/loginPath';

import LoginForm from '#auth/components/LoginForm';
import LoginWith from '#auth/features/Authentication/pages/login/components/LoginWith';

const Login: React.FC = () => {
  const { i18n } = useTranslation();
  const { persona, error, loading } = useProfile(true);
  const { error: errorQuery } = useQuery<{
    error?: string;
  }>();

  if (loading) {
    return <Loader />;
  }

  if ((!persona || persona !== PersonaType.VISITOR) && !error) {
    window.location.href = logoutPath();
    return;
  }

  return (
    <div className="flex h-screen items-center justify-center bg-primary-dark">
      <Container className="w-sm md:p-5">
        <a href={getEnvVar('VITE_MARKETING_PATH')} className="mb-3 flex justify-center">
          <LogoBrand size="large" />
        </a>
        {i18n.exists(`authentication:login.error.${errorQuery}`) && (
          <Typography variant="body" className="mb-2 text-center text-danger">
            <Trans i18nKey={`authentication:login.error.${errorQuery}`} />
          </Typography>
        )}
        <LoginForm />
        <LoginWith />
      </Container>
    </div>
  );
};

export default Login;
