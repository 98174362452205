import { get } from 'lodash';
import debounce from 'lodash.debounce';
import React, { ComponentProps, useCallback, useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import FormControl from '#shared/UI/Input/FormControl';
import TextInputUI from '#shared/UI/Input/TextInput';
import { FormRefContext } from '#shared/components/Form/Form';

type Props = Omit<ComponentProps<typeof FormControl>, 'inputId' | 'error'> & {
  name: string;
  type?: string;
  placeholder?: React.ReactNode;
  submitOnChange?: boolean;
};

const TextInput: React.FC<Props> = ({
  label,
  name,
  placeholder,
  submitOnChange,
  disabled = false,
  type,
  className,
}) => {
  const { control } = useFormContext();
  const formActionsRef = useContext(FormRefContext);

  const debounceOnChange = useCallback(
    debounce(() => formActionsRef?.current?.dispatchSubmit(), 500),
    [],
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, formState: { errors } }) => {
        const error = get(errors, name);

        const onChange = (value: string): void => {
          field.onChange(value);
          if (submitOnChange) {
            debounceOnChange();
          }
        };

        return (
          <FormControl
            disabled={disabled}
            className={className}
            label={label}
            inputId={name}
            error={error?.message as unknown as string}
          >
            <TextInputUI
              {...field}
              id={name}
              data-testid={name}
              disabled={disabled}
              type={type}
              error={!!error}
              placeholder={placeholder ? placeholder.toString() : undefined}
              onChange={(event) => {
                onChange(event.target.value);
              }}
            />
          </FormControl>
        );
      }}
    />
  );
};

export default TextInput;
