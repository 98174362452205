import clsx from 'clsx';
import React, { HtmlHTMLAttributes, PropsWithChildren } from 'react';

import Loader from '#shared/UI/Loader';

type Props = HtmlHTMLAttributes<HTMLDivElement> & {
  className?: string;
  loading?: boolean;
};

const Container: React.FC<PropsWithChildren<Props>> = ({ children, className, loading = false, ...props }) => (
  <div className={clsx('mb-3 break-inside-avoid rounded bg-white p-3 shadow last:mb-0', className)} {...props}>
    {loading && <Loader />}
    {!loading && children}
  </div>
);

export default Container;
