import { yupResolver } from '@hookform/resolvers/yup';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import useSWRMutation from 'swr/mutation';

import Button from '#shared/UI/Button';
import Modal from '#shared/UI/Modal';
import ModalBody from '#shared/UI/Modal/ModalBody';
import ModalFooter from '#shared/UI/Modal/ModalFooter';
import ModalHeader from '#shared/UI/Modal/ModalHeader';
import Typography from '#shared/UI/Typography';
import UserEntryPoints from '#shared/api/User/entrypoints';
import Image from '#shared/assets/img/bottle.png';
import Form from '#shared/components/Form/Form';
import TextInput from '#shared/components/Form/TextInput';
import useForm from '#shared/hooks/useForm';
import { writeItem } from '#shared/services/api';
import { FormViolation } from '#shared/typings/api';

import ForgotPasswordSchema from '#auth/schemas/ForgotPasswordSchema';

type FormProps = {
  email: string;
};

type Props = {
  email: string;
  isOpen: boolean;
  onRequestClose: () => void;
};

type ForgotPasswordProps = {
  identifier: string;
};

const ForgotPassword: React.FC<Props> = ({ email, isOpen, onRequestClose }) => {
  const [success, setSuccess] = useState<boolean>(false);
  const {
    trigger: forgotPassword,
    error,
    isMutating: loading,
  } = useSWRMutation<
    void,
    AxiosError<{
      violations: FormViolation[] | null;
    }>,
    string,
    ForgotPasswordProps
  >(UserEntryPoints.passwordForgotPath, writeItem('POST'));

  function onClose(): void {
    onRequestClose();
    setSuccess(false);
  }

  const formProps = useForm<FormProps>({
    defaultValues: { email },
    resolver: yupResolver<any>(ForgotPasswordSchema),
    violations: error?.response?.data?.violations,
    onSubmit: async (values) => {
      try {
        await forgotPassword({ identifier: values.email });
        setSuccess(true);
      } catch {
        setSuccess(false);
      }
    },
  });

  const {
    formState: { isValid, isSubmitting },
  } = formProps;

  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalHeader title={<Trans i18nKey="authentication:forgotPassword.title" />} />
      {success ? (
        <>
          <ModalBody className="text-center">
            <img src={Image} alt="" className="mx-auto w-[200px]" />
            <Typography variant="h3" className="mb-2">
              <Trans i18nKey="authentication:forgotPassword.success.subtitle" />
            </Typography>
            <Typography variant="subtitle">
              <Trans i18nKey="authentication:forgotPassword.success.description" values={{ email }} />
            </Typography>
          </ModalBody>
          <ModalFooter>
            <Button id="close-forgot-password" onClick={onClose} variant="outlined">
              <Trans i18nKey="authentication:forgotPassword.close" />
            </Button>
          </ModalFooter>
        </>
      ) : (
        <Form {...formProps} className="flex flex-1 flex-col">
          <ModalBody>
            <Typography variant="h3" className="mb-2">
              <Trans i18nKey="authentication:forgotPassword.subtitle" />
            </Typography>
            <Typography variant="subtitle" className="mb-3">
              <Trans i18nKey="authentication:forgotPassword.description" />
            </Typography>
            <TextInput name="email" label={<Trans i18nKey="authentication:forgotPassword.email" />} />
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              id="button-forgot-password"
              disabled={!isValid || isSubmitting || loading}
              loading={loading}
            >
              <Trans i18nKey="authentication:forgotPassword.valid" />
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Modal>
  );
};

export default ForgotPassword;
