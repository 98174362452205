import React, { useEffect, useRef } from 'react';
import {
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  useForm as useReactHookForm,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types/path';
import { useTranslation } from 'react-i18next';

import { getEnv } from '#shared/envs';
import { FormViolation } from '#shared/typings/api';

type FormActions = {
  dispatchSubmit: () => void;
};

export type UseFormRefReturn<
  TFieldValues extends FieldValues = FieldValues,
  TContext extends object = object,
> = UseFormReturn<TFieldValues, TContext> & {
  handleSubmitPromise: ReturnType<UseFormReturn<TFieldValues, TContext>['handleSubmit']>;
  formRef: React.RefObject<HTMLFormElement>;
  formActionsRef: React.RefObject<FormActions>;
};

type Props<TFieldValues extends FieldValues = FieldValues, TContext extends object = object> = UseFormProps<
  TFieldValues,
  TContext
> & {
  onSubmit: (data: TFieldValues, event?: React.BaseSyntheticEvent) => ReturnType<SubmitHandler<TFieldValues>>;
  onInvalid?: SubmitErrorHandler<TFieldValues>;
  violations?: FormViolation[] | null;
};

const useForm = <TFieldValues extends FieldValues = FieldValues, TContext extends object = object>({
  onSubmit,
  onInvalid,
  violations,
  ...props
}: Props<TFieldValues, TContext>): UseFormRefReturn<TFieldValues, TContext> => {
  const formMethods = useReactHookForm<TFieldValues, TContext>({
    mode: 'onChange',
    ...props,
  });
  const formRef = useRef<HTMLFormElement>(null);
  const formActionsRef = useRef<FormActions>(null);
  const {
    t,
    i18n: { exists },
  } = useTranslation('violations');
  const { handleSubmit, reset, setError } = formMethods;

  useEffect(() => {
    (violations ?? []).forEach((violation, index) => {
      setError(
        violation.propertyPath as FieldPath<TFieldValues>,
        {
          message: exists(`violations:${violation.message}`) ? t(`violations:${violation.message}`) : violation.message,
          type: 'validate',
        },
        { shouldFocus: 0 === index },
      );
    });
  }, [violations]);

  const handleErrors: SubmitErrorHandler<TFieldValues> = (errors): void => {
    if ('dev' === getEnv()) {
      console.error(errors);
    }
  };

  return {
    ...formMethods,
    handleSubmitPromise: handleSubmit((values, event) => {
      onSubmit(values, event);
      reset(values, { keepErrors: false });
    }, onInvalid || handleErrors),
    formRef,
    formActionsRef,
  };
};

export default useForm;
