import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Icon from '#shared/UI/Icon';
import Tooltip from '#shared/UI/Tooltip';
import Typography from '#shared/UI/Typography';
import { getEnvVar } from '#shared/envs';

type FormControlProps = {
  className?: string;
  label?: React.ReactNode;
  description?: string;
  help?: string;
  error?: string | { message: string; params: Record<string, any> };
  inputId?: string;
  disabled?: boolean;
};

const FormControl: React.FC<PropsWithChildren<FormControlProps>> = ({
  children,
  label,
  description,
  help,
  error,
  inputId,
  disabled = false,
  className,
}) => {
  const {
    i18n: { exists },
  } = useTranslation('shared');
  return (
    <div className={className}>
      {label && (
        <div className={clsx('flex items-start', disabled && 'opacity-40')}>
          <label htmlFor={inputId} className="mb-0.5 grow text-body-bold">
            {label}
          </label>
          {help && (
            <Tooltip label={help} direction="top">
              <Icon icon="question-mark" className={clsx('text-primary', !disabled && 'hover:opacity-65')} />
            </Tooltip>
          )}
        </div>
      )}
      {children}
      {error && (
        <Typography variant="caption" className={clsx('mt-0.5 text-danger', disabled && 'opacity-40')}>
          {exists('string' === typeof error ? error : error.message) ? (
            <Trans
              i18nKey={'string' === typeof error ? error : error.message}
              values={'object' === typeof error && error.params}
              components={{
                customer_link: <a href={getEnvVar('VITE_APP_HOST')} />,
              }}
            />
          ) : 'string' === typeof error ? (
            error
          ) : (
            error.message
          )}
        </Typography>
      )}
      {description && (
        <Typography variant="caption" className={clsx('mt-0.5', disabled && 'opacity-40')}>
          {description}
        </Typography>
      )}
    </div>
  );
};

export default FormControl;
