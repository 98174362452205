import { Slot } from '@radix-ui/react-slot';
import React, { PropsWithChildren } from 'react';

type Props = PropsWithChildren & {
  authorizationUrl: string;
  state: string;
  responseType: 'code' | 'token';
  redirectUri: string;
  clientId: string;
  accessType?: 'offline' | 'online';
  prompt?: 'consent';
  scope?: string[];
};

const OauthLogin: React.FC<Props> = ({
  state,
  authorizationUrl,
  clientId,
  redirectUri,
  responseType = 'code',
  accessType,
  prompt,
  scope = [],
  ...props
}) => {
  const authUrl = new URL(authorizationUrl);
  authUrl.searchParams.set('client_id', clientId);
  authUrl.searchParams.set('state', state);
  authUrl.searchParams.set('redirect_uri', redirectUri);
  authUrl.searchParams.set('scope', scope.join(' '));
  authUrl.searchParams.set('response_type', responseType);
  if (accessType) {
    authUrl.searchParams.set('access_type', accessType);
  }
  if (prompt) {
    authUrl.searchParams.set('prompt', prompt);
  }

  return (
    <Slot
      onClick={() => {
        window.location.assign(authUrl.toString());
      }}
      {...props}
    />
  );
};

export default OauthLogin;
