import { useState } from 'react';

type PropsOptions = {
  onTrue?: () => void;
  onFalse?: () => void;
};

type UseToggleHook = (initialValue?: boolean, options?: PropsOptions) => [boolean, (forceValue?: boolean) => void];

export const useToggle: UseToggleHook = (initialValue = false, options = {}) => {
  const [state, setState] = useState<boolean>(initialValue);

  function toggler(forceValue?: boolean): void {
    const nextState = forceValue !== undefined ? forceValue : !state;
    setState(nextState);

    if (nextState && options.onTrue) {
      options.onTrue();
    } else if (!nextState && options.onFalse) {
      options.onFalse();
    }
  }

  return [state, toggler];
};
