import clsx from 'clsx';
import React from 'react';

import { Size } from '#shared/UI/Foundation/typing';
import { getPartner } from '#shared/envs';

type Props = {
  className?: string;
  size?: Size;
};

const LogoBrand: React.FC<Props> = ({ className, size = 'medium' }) => {
  const partner = getPartner();

  if (partner) {
    return (
      <div
        className={clsx(className, size === 'large' && 'h-7', size === 'medium' && 'h-6', size === 'small' && 'h-5')}
      >
        <img className="h-2/3" src={partner.logo} alt={partner.slug} />
        <div className="mt-0.5 flex h-1/3 items-center justify-end gap-0.5">
          <b>&</b>
          <img className="h-full" src="/brands/stello/logo.svg" alt="stello" />
        </div>
      </div>
    );
  }

  return (
    <div className={clsx(className, size === 'large' && 'h-7', size === 'medium' && 'h-5', size === 'small' && 'h-4')}>
      <img className="h-full" src="/brands/stello/logo.svg" alt="stello" />
    </div>
  );
};

export default LogoBrand;
