import React from 'react';
import { Trans } from 'react-i18next';

import Button from '#shared/UI/Button';
import Typography from '#shared/UI/Typography';
import OauthLogin from '#shared/components/OAuthLogin';
import { getEnvVar } from '#shared/envs';

import { ReactComponent as IconGoogle } from '#auth/assets/img/google.svg';
import { redirectUri } from '#auth/features/Authentication/pages/login/components/LoginWith/index';

export default ({ redirect }: { redirect?: string }): React.ReactElement => (
  <OauthLogin
    state={JSON.stringify({
      redirect,
      action: 'login',
      redirectUri,
      provider: 'google',
    })}
    authorizationUrl={getEnvVar('VITE_GOOGLE_OAUTH_URL') as string}
    clientId={getEnvVar('VITE_GOOGLE_CLIENT_ID') as string}
    accessType="offline"
    prompt="consent"
    redirectUri={redirectUri}
    responseType="code"
    scope={['https://www.googleapis.com/auth/userinfo.email']}
  >
    <Button id="connect-google" className="min-w-[270px] border-grey-32 py-1" color="black" variant="outlined">
      <Typography variant="subtitle" className="flex items-center gap-1 font-normal">
        <IconGoogle className="h-3" />
        <Trans i18nKey="shared:partner.google.connect" />
      </Typography>
    </Button>
  </OauthLogin>
);
