import * as Sentry from '@sentry/react';
import React, { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import Loader from '#shared/UI/Loader';
import Notification from '#shared/UI/Notification';
import Error404 from '#shared/components/Error/404';
import Error500 from '#shared/components/Error/500';
import ScrollToTop from '#shared/components/ScrollToTop';
import SharedPath from '#shared/constants/paths';
import { getEnvVar } from '#shared/envs';
import { HubSpotConversationsWidgetRefresh } from '#shared/utils/hubspot';
import { UtmToCookie } from '#shared/utils/utm';

import Login from '#auth/features/Authentication/pages/login';

const Auth = React.lazy(() => import('#auth/features/Authentication/pages'));

const Router: React.FC = () => {
  return (
    <BrowserRouter basename={getEnvVar('VITE_BASE_NAME')}>
      <Notification />
      <Suspense fallback={<Loader />}>
        <ScrollToTop />
        <HubSpotConversationsWidgetRefresh />
        <UtmToCookie />
        <Routes>
          <Route path={SharedPath.NOT_FOUND} element={<Error404 />} />
          <Route path="/*" element={<Auth />} />
          <Route path="/" element={<Login />} />
          <Route path="*" element={<Navigate to={SharedPath.NOT_FOUND} replace />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Sentry.withErrorBoundary(Router, {
  fallback: () => <Error500 />,
  showDialog: true,
});
