import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';

import Button from '#shared/UI/Button';
import Typography from '#shared/UI/Typography';
import Form from '#shared/components/Form/Form';
import TextInput from '#shared/components/Form/TextInput';

import ForgotPassword from '#auth/components/ForgotPassword';
import usePasswordForm from '#auth/components/LoginForm/PasswordForm/usePasswordForm';

type Props = {
  email: string;
};

const PasswordForm: React.FC<Props> = ({ email }) => {
  const [isOpenForgotPassword, setIsOpenForgotPassword] = useState<boolean>(false);
  const { formProps, loading, disabled } = usePasswordForm(email);

  const { setFocus } = formProps;

  useEffect(() => {
    setFocus('password');
  }, [setFocus]);

  return (
    <>
      <Form {...formProps}>
        <TextInput name="password" type="password" placeholder={t('authentication:login.fields.password')} />
        <button type="button" className="mb-3 mt-2 text-primary" onClick={() => setIsOpenForgotPassword(true)}>
          <Typography variant="link-large">
            <Trans i18nKey="authentication:login.button.forgetPassword" />
          </Typography>
        </button>
        <Button id="submit-login" type="submit" className="mb-1 w-full" loading={loading} disabled={disabled}>
          <Trans i18nKey="authentication:login.button.connect" />
        </Button>
      </Form>

      <ForgotPassword
        email={email}
        isOpen={isOpenForgotPassword}
        onRequestClose={() => setIsOpenForgotPassword(false)}
      />
    </>
  );
};

export default PasswordForm;
