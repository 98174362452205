import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import useSWRMutation from 'swr/mutation';

import Button from '#shared/UI/Button';
import Icon from '#shared/UI/Icon';
import AuthenticationEntryPoints from '#shared/api/Authentication/entrypoints';
import { useToggle } from '#shared/hooks/toggle';
import { useQuery } from '#shared/hooks/useQuery';
import { writeItem } from '#shared/services/api';

import AuthCodeForm from '#auth/components/LoginForm/AuthCode';
import EmailForm from '#auth/components/LoginForm/EmailForm';
import PasswordForm from '#auth/components/LoginForm/PasswordForm';

const LoginForm: React.FC = () => {
  const { email: emailQuery, auth_code: authCodeQuery } = useQuery<{
    email?: string;
    auth_code?: string;
  }>();
  const [email, setEmail] = useState<undefined | string>(emailQuery);
  const [withCode, withCodeToggler] = useToggle(!!emailQuery && !!authCodeQuery);
  const [showPassword, showPasswordToggler] = useToggle();
  const { trigger: authCode } = useSWRMutation(AuthenticationEntryPoints.authCodePath, writeItem('POST'));

  if (withCode) {
    return <AuthCodeForm email={email ?? ''} authCode={authCodeQuery} />;
  }

  return (
    <>
      {!showPassword && (
        <EmailForm
          email={email ?? ''}
          onSubmitSuccess={(email) => {
            setEmail(email);
            showPasswordToggler();
          }}
        />
      )}
      {showPassword && email && (
        <>
          <button
            className="mx-auto mb-4 flex items-center gap-1 rounded border-2 border-tertiary-green px-1 py-0.5"
            type="button"
            onClick={() => showPasswordToggler()}
          >
            {email} <Icon icon="edit" />
          </button>

          <PasswordForm email={email} />

          <Button
            id="submit-code-connect"
            variant="outlined"
            className="w-full !gap-0.5"
            onClick={async () => {
              await authCode({ identifier: email.toLowerCase() });
              withCodeToggler();
            }}
          >
            <span className="max-sm:hidden">
              <Trans i18nKey="authentication:login.button.codeConnect" />{' '}
            </span>
            <span className="max-sm:capitalize">
              <Trans i18nKey="authentication:login.button.code" />
            </span>
          </Button>
        </>
      )}
    </>
  );
};

export default LoginForm;
