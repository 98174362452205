import clsx from 'clsx';
import React from 'react';
import { useFormContext } from 'react-hook-form';

type Props = {
  index: number;
};

const InputAuthCode: React.FC<Props> = ({ index }) => {
  const { register, setValue } = useFormContext();

  return (
    <input
      {...register(`authCode.${index}.value`, {
        valueAsNumber: true,
        onChange: (event) => {
          if (event.target.value !== '' && index !== 5) {
            document.getElementById(`authCode.${index + 1}.value`)?.focus();
          }
        },
      })}
      className={clsx(
        'w-[40px] bg-transparent bg-clip-text text-center placeholder:text-grey-16',
        index === 2 && 'mr-2',
        index === 3 && 'ml-2',
      )}
      id={`authCode.${index}.value`}
      placeholder="0"
      type="number"
      autoComplete="off"
      maxLength={1}
      onKeyDown={(event) => {
        const value = (document.getElementById(`authCode.${index}.value`) as HTMLInputElement)?.value;
        if (!!event.key.match(/^\d$/gi) && value !== '') {
          event.preventDefault();
          setValue(`authCode.${index}.value`, event.key, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
          });
          if (index !== 5) {
            document.getElementById(`authCode.${index + 1}.value`)?.focus();
          }
          return;
        }
        if (event.key === 'Backspace') {
          if ((document.getElementById(`authCode.${index}.value`) as HTMLInputElement)?.value !== '') {
            setValue(`authCode.${index}.value`, '', {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true,
            });
            event.preventDefault();
          } else if (index > 0) {
            document.getElementById(`authCode.${index - 1}.value`)?.focus();
          }
          return;
        }
        if (event.key === 'ArrowRight' && index !== 5) {
          document.getElementById(`authCode.${index + 1}.value`)?.focus();
          return;
        }
        if (event.key === 'ArrowLeft' && index > 0) {
          document.getElementById(`authCode.${index - 1}.value`)?.focus();
          return;
        }
      }}
      onPaste={(event) => {
        event.preventDefault();
        const paste = event.clipboardData.getData('text').trim().split('');
        if (paste.length) {
          for (let i = 0; i < Math.min(6, paste.length); i++) {
            setValue(`authCode.${i}.value`, paste[i], {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true,
            });
            if (paste.length - 1 === i && null !== document.getElementById(`authCode.${i}.value`)) {
              setTimeout(() => document.getElementById(`authCode.${i}.value`)?.focus(), 0);
            }
          }
        }
      }}
      onFocus={(event) => {
        if (index !== 0 && event.target.value === '') {
          for (let i = 0; i < 6; i++) {
            if ((document.getElementById(`authCode.${i}.value`) as HTMLInputElement).value === '' && i !== index + 1) {
              document.getElementById(`authCode.${i}.value`)?.focus();
              break;
            }
          }
        }
      }}
    />
  );
};

export default InputAuthCode;
