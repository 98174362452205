import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';

const ModalFooter: React.FC<PropsWithChildren<{ className?: string }>> = ({ children, className }) => (
  <footer
    className={clsx(
      'grid w-full gap-2 border-t-2 border-shade-opacity px-4 py-3',
      children && Array.isArray(children) && 'grid-cols-2',
      !Array.isArray(children) && 'grid-cols-1',
      className,
    )}
  >
    {children}
  </footer>
);

export default ModalFooter;
