import SharedPath from '#shared/constants/paths';
import { getEnvVar } from '#shared/envs';

export const loginPath = (redirectionPath?: string): string => {
  const path = `${getEnvVar('VITE_AUTH_HOST')}${SharedPath.LOGIN}`;
  return redirectionPath ? `${path}?r=${redirectionPath}` : path;
};

export const logoutPath = (redirectionPath?: string): string => {
  const path = `${getEnvVar('VITE_AUTH_HOST')}${SharedPath.LOGOUT}`;
  return redirectionPath ? `${path}?r=${redirectionPath}` : path;
};
