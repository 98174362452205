import clsx from 'clsx';
import React, { HTMLProps, PropsWithChildren } from 'react';

type Props = HTMLProps<HTMLDivElement> & {
  className?: string;
};

const ModalBody = React.forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ children, className, ...props }, forwardedRef) => (
    <div className={clsx('w-full grow p-4', className)} ref={forwardedRef} {...props}>
      {children}
    </div>
  ),
);

export default ModalBody;
