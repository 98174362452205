import clsx from 'clsx';
import React from 'react';
import { Trans } from 'react-i18next';

import Button from '#shared/UI/Button';
import Typography from '#shared/UI/Typography';
import Form from '#shared/components/Form/Form';

import InputAuthCode from '#auth/components/LoginForm/AuthCode/InputAuthCode';
import useAuthCode from '#auth/components/LoginForm/AuthCode/useAuthCode';

type Props = {
  email: string;
  authCode?: string;
};

const AuthCodeForm: React.FC<Props> = ({ email, authCode }) => {
  const { formProps, fields, error, message, resendCode, isRunning, currentDelay } = useAuthCode(email, authCode);

  return (
    <Form {...formProps}>
      <Typography variant="subtitle" className="text-center">
        <Trans i18nKey="authentication:authCode.description" values={{ email }} />
      </Typography>
      <div
        className={clsx(
          'relative mx-auto mt-2 flex w-fit items-center justify-center rounded border bg-primary-light px-4 py-3 text-h2',
          'after:absolute after:top-1/2 after:-translate-y-1/2 after:text-grey-60 after:content-["-"]',
          error ? 'border-danger text-danger' : 'border-grey-16 text-on-primary-light',
        )}
      >
        {fields.map((field, index) => (
          <InputAuthCode key={field.id} index={index} />
        ))}
      </div>
      {message && (
        <Typography variant="caption" className="mt-0.5 text-center text-danger">
          {message}
        </Typography>
      )}
      <Button
        id="submit-auth-code"
        className="mt-2 w-full"
        type="submit"
        variant="outlined"
        onClick={() => resendCode()}
        disabled={isRunning}
      >
        <span>
          <Trans i18nKey="authentication:authCode.resend">
            <span className="max-sm:hidden" />
          </Trans>
          {isRunning ? ` (${currentDelay})` : ''}
        </span>
      </Button>
    </Form>
  );
};

export default AuthCodeForm;
