import clsx from 'clsx';
import React, { createContext, FormHTMLAttributes, PropsWithChildren, ReactElement, useImperativeHandle } from 'react';
import { FieldValues, FormProvider } from 'react-hook-form';

import { UseFormRefReturn } from '#shared/hooks/useForm';

export const FormRefContext = createContext<UseFormRefReturn['formActionsRef'] | null>(null);

const Form = <T extends FieldValues = FieldValues>({
  children,
  formRef,
  formActionsRef,
  handleSubmitPromise,
  watch,
  getValues,
  getFieldState,
  setError,
  clearErrors,
  setValue,
  trigger,
  formState,
  resetField,
  reset,
  handleSubmit,
  unregister,
  control,
  register,
  setFocus,
  className,
  ...props
}: PropsWithChildren<UseFormRefReturn<T> & FormHTMLAttributes<HTMLFormElement>>): ReactElement => {
  useImperativeHandle(formActionsRef, () => ({
    dispatchSubmit: (): void => {
      formRef?.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    },
  }));

  const formProps = {
    watch,
    getValues,
    getFieldState,
    setError,
    clearErrors,
    setValue,
    trigger,
    formState,
    resetField,
    reset,
    handleSubmit,
    unregister,
    control,
    register,
    setFocus,
  };

  return (
    <FormProvider {...formProps}>
      <FormRefContext.Provider value={formActionsRef}>
        <form
          ref={formRef}
          className={clsx('w-full', className)}
          {...props}
          onSubmit={handleSubmitPromise}
          onChange={(event) => {
            if (props?.onChange) {
              props.onChange(event);
            }
          }}
        >
          {children}
        </form>
      </FormRefContext.Provider>
    </FormProvider>
  );
};

export default Form;
