import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useEffect, useState } from 'react';
import { FieldArrayWithId, useFieldArray } from 'react-hook-form';
import useSWRMutation from 'swr/mutation';

import AuthenticationEntryPoints from '#shared/api/Authentication/entrypoints';
import useForm, { UseFormRefReturn } from '#shared/hooks/useForm';
import { writeItem } from '#shared/services/api';

import { useAuth } from '#auth/hooks/useAuth';
import useTimer from '#auth/hooks/useTimer';
import AuthCodeSchema from '#auth/schemas/AuthCodeSchema';

type LoginAuthCodeProps = {
  email: string;
  auth_code: string;
};

type FormProps = {
  authCode: { value: string }[];
};

type HookReturn = {
  formProps: UseFormRefReturn<FormProps>;
  fields: FieldArrayWithId<FormProps, 'authCode'>[];
  error: boolean;
  message: string | null;
  resendCode: () => Promise<void>;
  isRunning: boolean;
  currentDelay: number;
};

const useAuthCode = (email: string, authCode?: string): HookReturn => {
  const { redirectAfterLogin, onLoginError } = useAuth();

  const [message, setMessage] = useState<string | null>(null);
  const { startTimer, isRunning, currentDelay } = useTimer(30);

  const { trigger: triggerLoginAuthCode } = useSWRMutation<
    {
      token: string;
    },
    void,
    string,
    LoginAuthCodeProps
  >(AuthenticationEntryPoints.loginAuthCodePath, writeItem('POST'));
  const { trigger: authCodeTrigger } = useSWRMutation(AuthenticationEntryPoints.authCodePath, writeItem('POST'));

  const loginAuthCode = async (payload: { email: string; authCode: string }): Promise<void> => {
    try {
      const response = await triggerLoginAuthCode({
        email: payload.email.toLowerCase(),
        auth_code: payload.authCode,
      });
      const token = response?.token;
      if (token) {
        await redirectAfterLogin(token);
      }
    } catch (err: any) {
      setMessage(err.response?.data?.message || null);
      await onLoginError(err);
    }
  };

  const formProps = useForm<FormProps>({
    defaultValues: {
      authCode: authCode
        ? authCode.split('').map((value) => ({ value }))
        : [{ value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }, { value: '' }],
    },
    resolver: yupResolver<any>(AuthCodeSchema),
    onSubmit: async (values) => {
      startTimer();
      await loginAuthCode({
        email,
        authCode: values.authCode.map(({ value }) => value).join(''),
      });
    },
  });

  const {
    formActionsRef,
    control,
    formState: { isValid, errors },
  } = formProps;

  const { fields } = useFieldArray({
    control,
    name: 'authCode',
  });

  useEffect(() => {
    startTimer();
  }, []);

  useEffect(() => {
    if (isValid) {
      formActionsRef.current?.dispatchSubmit();
    }
  }, [formActionsRef, isValid]);

  return {
    formProps,
    fields,
    error: !!errors.authCode || !!message,
    message,
    resendCode: async () => {
      await authCodeTrigger({ identifier: email.toLowerCase() });
      startTimer();
    },
    isRunning,
    currentDelay,
  };
};
export default useAuthCode;
