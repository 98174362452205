import { Position, TooltipPopup, useTooltip } from '@reach/tooltip';
import clsx from 'clsx';
import React from 'react';

import LabelTooltip from '#shared/UI/Tooltip/LabelTooltip';

export type Direction = 'top' | 'bottom' | 'left' | 'right';

type Props = {
  display?: string;
  label: React.ReactNode | string;
  direction?: Direction;
  isVisible?: boolean;
  size?: 'medium' | 'large';
};

function getPosition(
  triggerRect?: Parameters<Position>[0],
  tooltipRect?: Parameters<Position>[1],
  direction?: Direction,
): ReturnType<Position> {
  if (!triggerRect || !tooltipRect) {
    return {};
  }
  const leftCenter = triggerRect.left + triggerRect.width / 2;
  const left = leftCenter - tooltipRect.width / 2;
  const maxLeft = window.innerWidth - tooltipRect.width - 2;

  switch (direction) {
    case 'right':
      return {
        left: triggerRect.right + window.scrollX + 11,
        top: triggerRect.top + window.scrollY - tooltipRect.height / 2 + triggerRect.height / 2,
      };
    case 'left':
      return {
        left: triggerRect.left + window.scrollX - tooltipRect.width - 11,
        top: triggerRect.top + window.scrollY - tooltipRect.height / 2 + triggerRect.height / 2,
      };
    case 'top':
      return {
        left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
        top: triggerRect.top + window.scrollY - tooltipRect.height - 11,
      };
    default:
      return {
        left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
        top: triggerRect.bottom + window.scrollY + 11,
      };
  }
}

const Tooltip: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  display = 'inline-block',
  label,
  direction = 'bottom',
  isVisible = false,
  size = 'medium',
}) => {
  const [trigger, tooltip] = useTooltip();
  const { triggerRect } = tooltip;

  return (
    <>
      {React.cloneElement(<button style={{ display }}>{children}</button>, trigger)}
      <TooltipPopup
        {...tooltip}
        isVisible={isVisible ? isVisible : tooltip.isVisible}
        label={
          <LabelTooltip
            label={label}
            direction={direction}
            triggerRect={triggerRect}
            className={isVisible ? 'bg-primary' : 'bg-grey-60'}
          />
        }
        position={(triggerRect, tooltipRect) => getPosition(triggerRect, tooltipRect, direction)}
        className={clsx(
          'w-[200px] whitespace-normal rounded-button border-0 p-1 text-white md:w-auto',
          size === 'medium' ? 'md:max-w-[250px]' : 'md:max-w-[500px]',
          isVisible ? 'bg-primary' : 'bg-grey-60',
        )}
      />
    </>
  );
};

export default Tooltip;
