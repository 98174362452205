import { useState } from 'react';

type UseTimer = (duration: number) => {
  currentDelay: number;
  isRunning: boolean;
  startTimer: () => void;
};

const INTERVAL_DELAY = 1000;

const useTimer: UseTimer = (duration) => {
  const [delay, setDelay] = useState<number>(duration);
  const [isRunning, setIsRunning] = useState(false);

  const start = (): void => {
    if (isRunning) {
      return;
    }
    setIsRunning(true);
    setDelay(duration);
    const interval = setInterval(() => {
      setDelay((currentVal) => {
        if (currentVal > 0) {
          return currentVal - 1;
        }
        clearInterval(interval);
        setIsRunning(false);
        return 0;
      });
    }, INTERVAL_DELAY);
  };

  return { currentDelay: delay, isRunning, startTimer: start };
};
export default useTimer;
