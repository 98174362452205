import { Dialog } from '@reach/dialog';
import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';

import Button from '#shared/UI/Button';
import { Size } from '#shared/UI/Foundation/typing';

type Props = {
  open?: boolean;
  size?: Size;
  onClose?: () => any;
  dismissible?: boolean;
};

const Modal: React.FC<PropsWithChildren<Props>> = ({
  open = true,
  size = 'medium',
  onClose,
  children,
  dismissible = false,
}) => (
  <Dialog
    aria-label="modal"
    isOpen={open}
    onDismiss={dismissible ? onClose : undefined}
    className={clsx(
      'relative z-40 mx-auto flex w-full flex-col items-center !p-0 max-md:m-0 max-md:min-h-full md:my-[5vh] md:mb-3 md:max-w-[90vw]  md:rounded md:shadow-modal lg:max-w-[80vw]',
      size === 'small' && 'md:w-sm',
      size === 'medium' && 'md:w-md',
      size === 'large' && 'md:w-lg',
    )}
  >
    {onClose && (
      <Button
        id="button-close-modal"
        icon="close"
        variant="text"
        className="absolute right-3 top-2 z-10 p-0"
        color="black"
        onClick={onClose}
      />
    )}
    <div className="relative flex w-full grow flex-col bg-white md:rounded">{children}</div>
  </Dialog>
);

export default Modal;
