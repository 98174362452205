import clsx from 'clsx';
import React from 'react';

import Icon, { IconName } from '#shared/UI/Icon';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  leftIcon?: React.ReactElement | IconName;
  rightIcon?: React.ReactElement | IconName;
  error?: boolean;
  containerClassname?: string;
};

const TextInput = React.forwardRef<HTMLInputElement, Props>(
  (
    { type = 'text', leftIcon, rightIcon, error = false, className, containerClassname, value, ...inputProps },
    forwardedRef,
  ) => {
    return (
      <div className={clsx(containerClassname, 'relative')}>
        {leftIcon && (
          <div className="absolute left-2 top-1/2 flex -translate-y-1/2 items-center text-grey-60">
            {typeof leftIcon === 'string' ? <Icon icon={leftIcon} /> : leftIcon}
          </div>
        )}
        <input
          {...inputProps}
          value={value || ''}
          type={type}
          className={clsx(
            'h-6 w-full rounded border px-2 py-1.5 text-text outline-0 focus:border-primary focus:ring-1 focus:ring-primary disabled:cursor-not-allowed disabled:opacity-40',
            leftIcon && 'pl-5',
            rightIcon && 'pr-5',
            error ? 'border-danger' : 'border-grey-32',
            className,
          )}
          ref={forwardedRef}
        />
        {rightIcon && (
          <div className="absolute right-2 top-1/2 flex -translate-y-1/2 items-center text-grey-60">
            {typeof rightIcon === 'string' ? <Icon icon={rightIcon} /> : rightIcon}
          </div>
        )}
      </div>
    );
  },
);

export default TextInput;
