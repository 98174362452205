import clsx from 'clsx';
import React from 'react';

import Button from '#shared/UI/Button';
import Typography from '#shared/UI/Typography';

type Props = {
  title: React.ReactNode;
  className?: string;
  onGoBack?: () => void;
};

const ModalHeader: React.FC<Props> = ({ title, className, onGoBack }) => (
  <div className="relative flex w-full items-center border-b-2 border-shade-opacity px-4 py-3">
    {onGoBack && (
      <Button
        id="modal-go-back"
        icon="chevron-left"
        variant="text"
        className="absolute left-3 top-2 z-10 mr-2 p-0 font-normal"
        color="black"
        onClick={onGoBack}
      >
        <span className="max-md:hidden">Retour</span>
      </Button>
    )}
    <Typography variant="h6" className={clsx('grow text-center', className)}>
      {title}
    </Typography>
  </div>
);

export default ModalHeader;
