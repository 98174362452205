import React from 'react';
import { Trans } from 'react-i18next';

import Button from '#shared/UI/Button';
import Typography from '#shared/UI/Typography';
import OauthLogin from '#shared/components/OAuthLogin';
import { getEnvVar } from '#shared/envs';

import { redirectUri } from '#auth/features/Authentication/pages/login/components/LoginWith/index';

export default ({ redirect }: { redirect?: string }): React.ReactElement => (
  <OauthLogin
    state={JSON.stringify({
      redirect,
      action: 'login',
      redirectUri,
      provider: 'qonto',
    })}
    authorizationUrl={getEnvVar('VITE_QONTO_OAUTH_URL') as string}
    clientId={getEnvVar('VITE_QONTO_CLIENT_ID') as string}
    redirectUri={redirectUri}
    responseType="code"
    scope={[
      'organization.read',
      'offline_access',
      'membership.read',
      'insurance_contract.write',
      'insurance_contract.read',
    ]}
  >
    <Button id="connect-qonto" icon="qonto" className="min-w-[270px] !bg-qonto py-1.5 !text-white">
      <Typography variant="subtitle" className="font-normal">
        <Trans i18nKey="shared:partner.qonto.connect" />
      </Typography>
    </Button>
  </OauthLogin>
);
