import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { t } from 'i18next';
import useSWRMutation from 'swr/mutation';
import * as Yup from 'yup';

import AuthenticationEntryPoints from '#shared/api/Authentication/entrypoints';
import useForm, { UseFormRefReturn } from '#shared/hooks/useForm';
import { writeItem } from '#shared/services/api';

import { useAuth } from '#auth/hooks/useAuth';

type FormProps = {
  password: string;
};

type HookReturn = {
  formProps: UseFormRefReturn<FormProps>;
  loading: boolean;
  disabled: boolean;
};

const usePasswordForm = (email: string): HookReturn => {
  const { redirectAfterLogin, onLoginError } = useAuth();

  const { trigger: triggerLogin, isMutating } = useSWRMutation<
    {
      token: string;
    },
    void,
    string,
    {
      email: string;
      password: string;
    }
  >(AuthenticationEntryPoints.login, writeItem('POST'));

  const formProps = useForm<FormProps>({
    defaultValues: { password: '' },
    resolver: yupResolver<any>(
      Yup.object().shape({
        password: Yup.string().required(t('authentication:login.assert.required')),
      }),
    ),
    onSubmit: async (values) => {
      try {
        const response = await triggerLogin({
          email: email.toLowerCase(),
          password: values.password,
        });
        const token = response?.token;
        if (token) {
          await redirectAfterLogin(token);
        }
      } catch (err: any) {
        formProps.setError('password', { type: 'validate', message: err.response?.data?.message });
        await onLoginError(err);
      }
    },
  });

  const {
    formState: { isValid, isSubmitting },
  } = formProps;

  return {
    formProps,
    loading: isMutating,
    disabled: !isValid || isSubmitting || isMutating,
  };
};
export default usePasswordForm;
