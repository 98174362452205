import clsx from 'clsx';
import React, { ComponentProps } from 'react';

import Tooltip, { Direction } from '#shared/UI/Tooltip/index';
import Typography from '#shared/UI/Typography';

type Props = Pick<ComponentProps<typeof Tooltip>, 'label'> & {
  triggerRect: DOMRect | null;
  direction: Direction;
  className: string;
};

const LabelTooltip: React.FC<Props> = ({ label, direction, triggerRect, className }) => {
  let style = {};
  switch (direction) {
    case 'right':
      style = {
        left: `${triggerRect && triggerRect.right + 7}px`,
        top: `${triggerRect && triggerRect.top + triggerRect.height / 2 - 4}px`,
      };
      break;
    case 'left':
      style = {
        left: `${triggerRect && triggerRect.left - 15}px`,
        top: `${triggerRect && triggerRect.top + triggerRect.height / 2 - 4}px`,
      };
      break;
    case 'top':
      style = {
        left: `${triggerRect && triggerRect.left - 4 + triggerRect.width / 2}px`,
        top: `${triggerRect && triggerRect.top - 15}px`,
      };
      break;
    case 'bottom':
      style = {
        left: `${triggerRect && triggerRect.left - 4 + triggerRect.width / 2}px`,
        top: `${triggerRect && triggerRect.bottom + 7}px`,
      };
      break;
  }

  return (
    <Typography variant="caption">
      {label}
      {/*triangle*/}
      <div style={style} className={clsx('fixed h-1 w-1 rotate-45 rounded-[1px]', className)} />
    </Typography>
  );
};

export default LabelTooltip;
