import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useSWRMutation from 'swr/mutation';
import * as Yup from 'yup';

import Button from '#shared/UI/Button';
import Typography from '#shared/UI/Typography';
import UserEntryPoints from '#shared/api/User/entrypoints';
import Form from '#shared/components/Form/Form';
import TextInput from '#shared/components/Form/TextInput';
import useForm from '#shared/hooks/useForm';
import { generateApiPath, writeItem } from '#shared/services/api';

type FormProps = {
  email: string;
};

type Props = {
  email: string;
  onSubmitSuccess: (email: string) => void;
};

const EmailForm: React.FC<Props> = ({ email, onSubmitSuccess }) => {
  const { t } = useTranslation();

  const formProps = useForm<FormProps>({
    defaultValues: { email: email },
    resolver: yupResolver<any>(
      Yup.object().shape({
        email: Yup.string()
          .email(t('authentication:login.assert.email'))
          .required(t('authentication:login.assert.required')),
      }),
    ),
    onSubmit: async () => {
      await emailExist({});
    },
  });

  const {
    getValues,
    setError,
    watch,
    setFocus,
    formState: { isValid, isSubmitting },
  } = formProps;

  const currentEmail = watch('email');

  const { trigger: emailExist, isMutating: loading } = useSWRMutation(
    generateApiPath(UserEntryPoints.headByEmailPath, { email: currentEmail.toLowerCase() }),
    writeItem('HEAD'),
    {
      onSuccess: () => onSubmitSuccess(getValues()['email']),
      throwOnError: false,
      onError: () => {
        setFocus('email');
        setError('email', {
          type: 'validate',
          message: t('authentication:login.assert.emailNotFound'),
        });
      },
    },
  );

  useEffect(() => {
    setFocus('email');
  }, [setFocus]);

  return (
    <Form {...formProps}>
      <Typography variant="subtitle-bold" className="text-center">
        <Trans i18nKey="authentication:login.title" />
      </Typography>
      <TextInput name="email" className="mb-2 mt-3" placeholder={t('authentication:login.fields.email')} />
      <Button
        id="submit-login"
        type="submit"
        className="w-full"
        loading={loading}
        disabled={!isValid || isSubmitting || loading}
      >
        <Trans i18nKey="authentication:login.button.continue" />
      </Button>
    </Form>
  );
};

export default EmailForm;
