import * as Sentry from '@sentry/react';
import { setDefaultOptions } from 'date-fns';
import { fr } from 'date-fns/locale';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import '#shared/assets/css/index.css';
import { getEnvVar } from '#shared/envs';
import '#shared/schemas/YupExtensions';
import { boostrapInterceptorRequest } from '#shared/services/api';
import { onRequestAuthentication } from '#shared/services/switchUser';
import { removeToken } from '#shared/services/token';

import App from '#auth/App';
import '#auth/i18n';

if (getEnvVar('VITE_SENTRY_DSN_AUTH') && getEnvVar('VITE_ENV')) {
  Sentry.init({
    environment: getEnvVar('VITE_ENV'),
    release: window.APP_VERSION,
    dsn: getEnvVar('VITE_SENTRY_DSN_QUOTE'),
    autoSessionTracking: true,
    normalizeDepth: 8,
    ignoreErrors: [/^Non-Error promise rejection captured with value/],
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.captureConsoleIntegration({ levels: ['error'] }),
      Sentry.httpClientIntegration(),
      Sentry.extraErrorDataIntegration(),
    ],
    tracePropagationTargets: [new URL(getEnvVar('VITE_API_ENDPOINT') as string).host],
    sendDefaultPii: true,
    tracesSampleRate: 1.0,
  });
}

boostrapInterceptorRequest(onRequestAuthentication, removeToken, () => {
  (window as any).location = `/logout?r=${(window as any).location.href}`;
});

setDefaultOptions({ locale: fr });

createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </React.StrictMode>,
);
