import axios from 'axios';
import { useLocation } from 'react-router';

import { getEnvVar } from '#shared/envs';
import { useProfile } from '#shared/hooks/useProfile';
import { persistToken, setSessionToken } from '#shared/services/token';

type AuthHookState = {
  redirectAfterLogin: (token: string, redirectPath?: string) => Promise<void>;
  onLoginError: (err: any) => Promise<void>;
};

const isValidUrl = (urlString: string): boolean => {
  try {
    return Boolean(new URL(urlString));
  } catch (_e) {
    return false;
  }
};

export const useAuth = (): AuthHookState => {
  const { search } = useLocation();
  setSessionToken(search);

  const { mutate } = useProfile(true);

  return {
    redirectAfterLogin: async (token: string, redirectPath?: string): Promise<void> => {
      persistToken(token);

      const profile = await mutate();
      if (redirectPath) {
        window.location.href = redirectPath;
        return;
      }
      let path = '';
      if (search.includes('r=')) {
        const redirectParams = new URLSearchParams(search).get('r');
        if (null !== redirectParams && '/' !== redirectParams) {
          path = redirectParams;
        }
        const redirectTo = path.indexOf('/logout') === -1 ? path : '/';

        if (profile?.user && redirectTo !== '' && isValidUrl(redirectTo)) {
          window.location.href = redirectTo;
          return;
        }
      }
      if (profile?.roles.some((role) => role === 'ROLE_MANAGER' || role === 'ROLE_ADMINISTRATOR')) {
        window.location.href = getEnvVar('VITE_EXTRANET_HOST') as string;
        return;
      }
      if (profile?.roles.includes('ROLE_CUSTOMER')) {
        window.location.href = getEnvVar('VITE_APP_HOST') as string;
        return;
      }
    },
    onLoginError: async (err: any) => {
      if (!axios.isAxiosError(err)) {
        throw err;
      }
      await mutate();
    },
  };
};
