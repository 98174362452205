enum UserEntryPoints {
  putEmailUpdateRequestResetPath = '/v1/users/{id}/email-update-request/reset',
  putEmailUpdateRequestInitPath = '/v1/users/{id}/email-update-request/init',
  putEmailUpdateRequestValidatePath = '/v1/users/{id}/email-update-request/validate',
  headByEmailPath = '/v1/users/{email}/by-email',
  passwordPath = '/v1/users/{id}/password',
  passwordForgotPath = '/v1/users/password/forgot',
  passwordResetPath = '/v1/users/password/reset',
}

export default UserEntryPoints;
